.ext {
  margin: 20px;
}
.box {
  display: flex;
  align-items: center;
}
.box .imgBox {
  margin-right: 10px;
}
.title {
  height: 44px;
  font-family: "Chakra Petch";
  font-weight: 600;
  font-size: 34px;
  line-height: 44px;
  color: #ffffff;
}
.text {
  height: 66px;
  font-family: " Noto Sans";
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
}

@media (max-width:500px) {
  .title {
    height: auto;
    font-family: "Chakra Petch";
    font-weight: 600;
    font-size: 24px;
    line-height: 44px;
    color: #ffffff;
  }
  .text {
    height: auto;
    font-family: " Noto Sans";
    font-weight: 500;
    font-size: 18px;
    line-height: 33px;
    color: #ffffff;
  }
}