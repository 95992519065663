.home {
  width: 100%;
  max-width: 1440px;
  background: #ffffff;
}
.relet {
  position: relative;
}
.relet .imgBox {
  width: 219px;
  position: absolute;
  bottom: 260px;
  right: 50px;
  z-index: 1000;
}
.relet .imgBox img {
  width: 100%;
}
.relet .text {
  height: 62px;
  font-family: "Chakra Petch";
  font-weight: 600;
  font-size: 48px;
  line-height: 62px;
  color: #321eb3;
  text-transform: uppercase;
  text-align: right;
}
.relet .text2 {
  height: 65px;
  text-transform: uppercase;
  font-family: "Chakra Petch";
  font-weight: 600;
  font-size: 50px;
  color: #321eb3;
  text-align: right;
}
.underdev {
  height: 227px;
  background: #6241ff;
  display: flex;
  align-items: center;
  justify-content: end;
  clip-path: polygon(0 20%, 100% 0%, 100% 100%, 0 100%);
  padding-right: 100px;
}
.underdev .forThisP {
  height: 39px;
  font-family: "Chakra Petch";
  font-weight: 600;
  font-size: 30px;
  line-height: 39px;
  color: #ffffff;
  margin-right: 50px;
}
.forSlide {
  padding: 50px 0 50px 0;
  background: #ffffff;
  box-shadow: 5px 10px 15px 2px rgba(0, 0, 0, 0.1);
  align-items: center;
}
footer {
  margin: 50px;
}
footer .logoArea {
  display: flex;
  align-items: center;
}
footer .logoArea .forLogo {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
footer .logoArea .forLogo img {
  width: 100%;
}
footer .textArea {
  width: 125px;
  height: 31px;
  font-family: "Chakra Petch";
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #1e1e1e;
  display: flex;
}
footer .textArea span {
  color: #6241ff;
}
footer p {
  font-family: "Chakra Petch";
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #4c5862;
}

@media (max-width: 1300px) {
  .relet .imgBox {
    width: 180px;
    position: absolute;
    bottom: 220px;
    right: 50px;
    z-index: 1000;
  }
  .relet .text {
    font-size: 40px;
    line-height: 52px;
  }
  .relet .text2 {
    font-size: 40px;
    line-height: 55px;
  }
}
@media (max-width: 1100px) {
  .relet .imgBox {
    width: 150px;
    position: absolute;
    bottom: 220px;
    right: 50px;
    z-index: 1000;
  }
  .relet .text {
    font-size: 32px;
  }
  .relet .text2 {
    font-size: 32px;
  }
}
@media (max-width: 920px) {
  .relet .imgBox {
    width: 150px;
    bottom: 180px;
    right: 50px;
  }
}
@media (max-width: 870px) {
  .relet .text {
    font-size: 26px;
  }
  .relet .text2 {
    font-size: 26px;
  }
  .underdev {
    height: 180px;
  }
  .underdev .forThisP {
    font-size: 25px;
    margin-right: 30px;
  }
}
@media (max-width: 760px) {
  .relet .imgBox {
    width: 120px;
  }
  .underdev {
    height: 150px;
  }
  .underdev .forThisP {
    font-size: 20px;
    margin-right: 20px;
  }
}
@media (max-width: 700px) {
  .relet .text {
    font-size: 22px;
  }
  .relet .text2 {
    font-size: 22px;
  }
  .underdev {
    height: 150px;
    display: grid;
    grid-template-columns: 100%;
    justify-items: center;
  }
}
@media (max-width: 660px) {
  .relet .imgBox {
    width: 100px;
  }
  .relet .text {
    font-size: 20px;
  }
  .relet .text2 {
    font-size: 20px;
  }
}
@media (max-width: 560px) {
  .relet .text {
    font-size: 18px;
  }
  .relet .text2 {
    font-size: 18px;
  }
}
@media (max-width: 490px) {
  .relet .imgBox {
    width: 80px;
    bottom: 150px;
    right: 50px;
  }
  .relet .text {
    font-size: 16px;
    line-height: 30px;
  }
  .relet .text2 {
    font-size: 16px;
    line-height: 30px;
  }
}
