.logoArea{
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 20px;
}
.logoArea .forLogo {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.logoArea .forLogo img {
  width: 100%;
}
.textArea {
  font-family: "Chakra Petch";
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #1e1e1e;
  display: flex;
}
.textArea span {
  color: #fff;
}

@media (max-width:420px) {
  .logoArea{
    margin-right: 5px;
  }
  .logoArea .forLogo {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .textArea{
    font-size: 18px;
  }
}