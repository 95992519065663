.contact {
  display: flex;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(119, 106, 193, 0.15);
  border-radius: 5px;
  margin: 0 50px 0 0;
}
.left {
  width: 100%;
  max-width: 389px;
  background: #6241ff;
  position: relative;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.left .text {
  height: 38px;
  max-width: 389px;
  font-family: "Noto Sans";
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin: 0 20px;
}
.mailPart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.mail {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  margin-left: 20px;
}
.mail img {
  width: 100%;
}
.mailPart p {
  height: 19px;
  font-family: "Noto Sans";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}
.left .imgBox {
  width: 219px;
  position: absolute;
  top: 200px;
  right: -25px;
  z-index: 1000;
}
.left .imgBox img {
  width: 100%;
}
.right {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 100px;
  grid-template-columns: 6fr 6fr;
}
.lChild {
  margin: 0 50px 0 50px;
}
.forinps p {
  height: 16px;
  font-family: "Noto Sans";
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #4c5862;
}
.forinps input {
  width: 100%;
  height: 48px;
  border: 1px solid #abaaaa;
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 16px;
}
.forinps input::placeholder {
  display: flex;
  justify-content: start;
  align-items: center;
}
.forMessage input {
  width: 100%;
  max-width: 383px;
  height: 244px;
  border: 1px solid #abaaaa;
  box-sizing: border-box;
  border-radius: 5px;
}
.forMessage p {
  height: 16px;
  font-family: "Noto Sans";
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #4c5862;
}
.compBtn {
  display: flex;
  justify-content: end;
  margin-bottom: 30px;
  margin-right: 50px;
}
.button {
  height: 48px;
  width: 220px;
  background-color: #6241ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
}
.button p {
  color: #ffffff;
}
@media (max-width: 1050px) {
  .right {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }
  .forMessage input {
    width: 100%;
    max-width: 383px;
    height: 100px;
    border: 1px solid #abaaaa;
    box-sizing: border-box;
    border-radius: 5px;
  }
  .forMessage {
    margin: 0 50px 0 50px;
  }
  .compBtn {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    margin-right: 0;
  }
}
@media (max-width: 610px) {
  .contact {
    display: grid;
    grid-template-columns: 100%;
  }
  .imgBox {
    display: none;
  }
  .left {
    max-width: 650px;
  }
}
