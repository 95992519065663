.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  cursor: pointer;
}
.btn p {
  font-family: " Noto Sans";
  color: #1e1e1e !important;
  display: flex;
  justify-content: center;
}

@media (max-width: 750px) {
  .btn {
    width: 160px;
    height: 34px;
  }
  .btn p {
    font-size: 16px;
  }
}
