.dark {
  margin-top: 80px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dark p {
  font-family: "Chakra Petch";
  font-weight: 500;
  font-size: 54px;
  color: #1e1e1e;
}

@media (max-width: 1310px) {
  .dark {
    margin-top: 80px;
    margin-bottom: 20px;
    margin-left: 20px;
  }
  .dark p {
    font-size: 48px;
  }
}

@media (max-width: 850px) {
  .dark {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .dark p {
    font-size: 36px;
  }
}
@media (max-width: 520px) {
  .dark {
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .dark p {
    font-size: 24px;
  }
}
