.imgPart {
  width: 100%;
  max-width: 670px;
  height: 440px;
  margin: 30px 30px 0 0;
}
.imgPart img {
  width: 100%;
}
@media (max-width: 600px) {
  .imgPart {
    width: 100%;
    max-width: 670px;
    height: auto;
    margin: 30px 30px 0 0;
  }
}
