.header {
  height: 64px;
  background-color: #6241ff;
  display: grid;
  grid-template-columns: 3fr 6fr;
  justify-items: center;
  box-shadow: 0px 8px 0px 0px rgba(50, 30, 179, 0.5);
}
.languageArea {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.languageArea .eng {
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #000;
  margin-right: 15px;
  cursor: pointer;
}
.languageArea .itl {
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #1e1e1e;
  cursor: pointer;
}
.languageArea:last-child div {
  margin: 0 50px 0 30px;
}
@media (max-width: 660px) {
  .languageArea:last-child div {
    margin: 0 20px 0 10px;
  }
}

@media (max-width: 510px) {
  .languageArea:last-child div {
    margin: 0 10px 0 0;
  }
  .languageArea .eng {
    font-size: 16px;
  }
  .languageArea .itl {
    font-size: 16px;
  }
}
