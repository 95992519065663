.topMain {
  background: #6241ff;
  display: flex;
  justify-content: space-between;
  clip-path: polygon(0 0, 100% 0%, 100% 80%, 0% 100%);
}
.info {
  font-family: "Chakra Petch";
  font-weight: 600;
  font-size: 90px;
  line-height: 117px;
  text-transform: uppercase;
  color: #ffffff;
  margin-left: 100px;
}
@media (max-width: 1300px) {
  .info {
    font-size: 75px;
  }
}
@media (max-width: 1100px) {
  .info {
    font-size: 60px;
  }
}
@media (max-width: 920px) {
  .info {
    font-size: 45px;
  }
}

@media (max-width: 760px) {
  .info {
    font-size: 40px;
    margin-left: 50px;
  }
}

@media (max-width: 660px) {
  .info {
    font-size: 30px;
    margin-left: 20px
  }
}
@media (max-width: 490px) {
  .info {
    font-size: 25px;
  }
}
@media (max-width: 390px) {
  .info {
    font-size: 18px;
  }
}