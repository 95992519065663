.infoPart {
  justify-content: center;
  flex-wrap: nowrap;
  display: flex;
  margin-left: 50px;
}
.links {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 15px 2px rgba(119, 106, 193, 0.15);
  border-radius: 5px;
  padding-top: 30px;
  margin-right: 50px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
}
.links .elStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.links p {
  cursor: pointer;
  margin-left: 40px;
  margin-top: 20px;
  font-family: "Chakra Petch";
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
  color: #1e1e1e;
}
.elImg {
  width: 24px;
  margin-right: 15px;
}
.elImg img {
  width: 100%;
}
.textPart {
  width: 100%;
  max-width: 700px;
  background: #ffffff;
  box-shadow: 0px 4px 15px 2px rgba(119, 106, 193, 0.15);
  border-radius: 5px;
}
.textPart p {
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 600;
  font-size: 120px;
  margin: 20px;
}
.textPart .likeP {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  color: #1e1e1e;
  margin: 20px;
}

@media (max-width: 830px) {
  .infoPart {
    display: grid;
    grid-template-columns: 100%;
    margin-left: 20px;
  }
  .elStyle p {
    font-size: 18px;
  }
  .textPart .likeP {
    font-size: 18px;
  }
}
