.main {
  background: #6241ff;
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 50px;
}

@media (max-width: 800px) {
  .main {
    grid-template-columns: 100%;
    padding: 20px;
  }
}
